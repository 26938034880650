import { Component } from 'react';
import BaseClass from './baseClass';


//model
export default class FormItem extends BaseClass {
  constructor(props){
    super(props);


  }


  render(){
  return (
    <div>
    </div>
  )}
}
